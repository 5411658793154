import logo from '@/assets/logo.png'
import Routerx from '@/routerx'
import { useStore } from '@/store'
import { App, Button, Card, Checkbox, Form, Input } from 'antd'
import './index.css'

const Login = () => {
  const { message } = App.useApp()
  const { loginStore } = useStore()
  const onFinish = async (values: { username: string; password: string }) => {
    const { username, password } = values
    try {
      await loginStore.getToken({ username, password })
      message.success('登录成功')
      Routerx.navigate('/', { replace: true })
    } catch (e) {
      const err = e as Error
      message.error(err.message || '登录失败')
    }
  }

  return (
    <div className="login">
      <Card className="login-container">
        <img
          className="login-logo"
          src={logo}
          alt=""
        />
        {/* 登录表单 */}
        <Form
          onFinish={onFinish}
          initialValues={{
            remember: true,
          }}
          validateTrigger={['onBlur', 'onChange']}
          autoComplete="off"
        >
          <Form.Item
            name="username"
            rules={[
              {
                pattern: /^[a-zA-Z][a-zA-Z0-9_]{0,24}$/,
                message: '用户名格式不对',
                validateTrigger: 'onBlur',
              },
              { required: true, message: '请输入用户名' },
            ]}
          >
            <Input
              size="large"
              placeholder="请输入用户名"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                pattern: /^[a-zA-Z][a-zA-Z0-9_]{0,16}$/,
                len: 16,
                message: '请输入密码',
                validateTrigger: 'onBlur',
              },
              { required: true, message: '请输入密码' },
            ]}
          >
            <Input.Password
              size="large"
              placeholder="请输入密码"
              maxLength={16}
            />
          </Form.Item>
          <Form.Item
            name="remember"
            valuePropName="checked"
          >
            <Checkbox className="login-checkbox-label">我已阅读并同意「用户协议」和「隐私条款」</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default Login
